/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Hero } from '../components/Hero'
import Layout from '../components/Layout'
import CTASection from '../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import theme from '../theme'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { Feature } from '../components/Feature'
import Block from '../components/Block'
import styled from 'styled-components'
import Customers from '../components/CustomerLogos'
import Page from '../components/Page'
import { LeadHeading } from '../components/LeadHeading'
import ThreeColumns from '../components/ThreeColumns'
import MainDemoRequestCTA from '../components/MainDemoRequestCTA'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import { Link } from 'gatsby'
//9 feature icons
import ReviewTime from '../assets/images/icons/clock.svg'
import WIPLimits from '../assets/images/icons/checklist.svg'
import ReviewRate from '../assets/images/icons/percentage.svg'
import InvestmentInsights from '../assets/images/icons/barchart.svg'
import BatchSize from '../assets/images/icons/size.svg'
import CycleTime from '../assets/images/icons/cycle.svg'
import CIInsights from '../assets/images/icons/robot-hand.svg'
import BugInsights from '../assets/images/icons/castle.svg'
import DeploymentFrequency from '../assets/images/icons/rocket.svg'
//Explore next
import { ReadNext } from '../components/ReadNext'
import continuousIcon from '../assets/images/icons/ways.svg'
import QuoteBlock from '../components/blocks/QuoteBlock'

const SwarmiaForDevsPage = () => {
  const developerImages = useStaticQuery(graphql`
    query {
      feature1: file(relativePath: { eq: "images/features/pr-view.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-flow-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(
        relativePath: { eq: "images/engineering-metrics/feature-health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      glance1: file(
        relativePath: { eq: "images/for-developers/plug-in-tools.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      glance2: file(
        relativePath: {
          eq: "images/engineering-metrics/glance-no-harmful-metrics.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
            height: 300
          )
        }
      }
      glance3: file(
        relativePath: { eq: "images/for-developers/get-actionable-alerts.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      feature4: file(
        relativePath: { eq: "images/for-developers/digest-big.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature5: file(
        relativePath: { eq: "images/for-developers/feature-review-wa-big.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="A better way to do GitHub reporting"
      description="Swarmia allows you to combine GitHub metrics with data from your issue tracker for a more holistic view into engineering productivity and bottlenecks."
    >
      <Hero
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../assets/images/backgrounds/git-analytics.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>A better way to do GitHub reporting</h1>
          <div className="hero-description">
            <p className="large_text">
              Swarmia allows you to not only track healthy GitHub metrics but
              also combine them with issue tracker insights and actionable Slack
              alerts.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="padding-bottom-small padding-top-none layout-index">
        <Customers />
        <Block type="padding-top-small padding-bottom-medium">
          <LeadHeading title="GitHub reporting, the Swarmia way" />
          <ThreeColumns>
            <div className="columns columns-reverse three-columns">
              <div className="column">
                <h4>
                  Pull data from GitHub and your issue tracker, whether that’s
                  Jira or Linear
                </h4>
                <div>
                  <GatsbyImage
                    image={
                      developerImages.glance1.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  Focus on healthy team-based metrics instead of harmful
                  leaderboards
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance2.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  Identify improvement areas and take action with Working
                  Agreements
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance3.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <Block size="large" type="padding-bottom-large padding-top-small">
          <LeadHeading
            title="Much more than a GitHub dashboard"
            description="In addition to giving you access to healthy GitHub metrics, Swarmia helps you combine that data with Flow insights from your issue tracker."
          />
          <div className="features full-width-images" id="features">
            <Feature
              type="feature feature-grid-right"
              heading="Healthy GitHub metrics only"
              image={developerImages.feature1.childImageSharp.gatsbyImageData}
              imageAlt="Swarmia's working agreements"
            >
              <p>
                Just because you can measure a certain GitHub metric doesn’t
                mean you should.
              </p>
              <p>
                Swarmia only surfaces research-backed engineering metrics, such
                as pull request cycle time, review rate, and deployment
                frequency. In fact, all the metrics in Swarmia have a proven
                correlation with engineering productivity and developer
                experience.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="Combine GitHub data with insights from Jira or Linear"
              image={developerImages.feature2.childImageSharp.gatsbyImageData}
              imageAlt="Flow insights in Swarmia"
            >
              <p>
                Swarmia combines useful GitHub metrics with with data from your
                issue tracker, whether that’s Jira or Linear.
              </p>
              <p>
                Alternatively, you can get started with Swarmia’s Lite plan,
                which includes our GitHub and Slack integrations. It’s perfect
                for teams who want to streamline their pull request workflows.
              </p>
              <p>
                <Link to="/pricing/">
                  Read more about Swarmia plans and pricing →
                </Link>
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Drive positive change with Working Agreements"
              image={developerImages.feature3.childImageSharp.gatsbyImageData}
              imageAlt="Swarmia's Working Agreements"
            >
              <p>
                The big problem with most GitHub dashboards and analysis tools
                is that they don’t help teams to make concrete improvements.
              </p>
              <p>
                Working Agreements in Swarmia, on the other hand, encourage your
                team to adopt healthy habits to limit work-in-progress, increase
                collaboration, prioritize code reviews, and more.
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="alex4" />

        <FeaturesGridWrapper>
          <Block size="large" type="padding-large">
            <div className="lead-heading">
              <h2>Some of the GitHub metrics in Swarmia</h2>
              <p>Here are some of the healthy metrics you’ll see in Swarmia.</p>
            </div>
            <FeaturesGrid>
              <div>
                <img src={CycleTime} alt="Cycle time" />
                <h4>PR cycle time</h4>
                <p>
                  Understand how long it takes for your team to complete pull
                  requests so you can reduce waiting times and merge faster.
                </p>
              </div>
              <div>
                <img src={DeploymentFrequency} alt="Deployment frequency" />
                <h4>Deployment frequency</h4>
                <p>
                  Keep track of how often you’re deploying — and how often the
                  deployments are failing.
                </p>
              </div>
              <div>
                <img src={InvestmentInsights} alt="Investment distribution" />
                <h4>Investment distribution</h4>
                <p>
                  Know what your engineering teams are working on and establish
                  the right balance between planned and unplanned work.
                </p>
              </div>
              <div>
                <img src={BugInsights} alt="Bug Insights" />
                <h4>Bug Insights</h4>
                <p>
                  Stay on top of the bugs your team is working on and the time
                  it takes to fix them.
                </p>
              </div>
              <div>
                <img src={BatchSize} alt="Batch size" />
                <h4>Batch size</h4>
                <p>
                  Keep your batch size small to roll out features to customers
                  faster and with less risk.
                </p>
              </div>
              <div>
                <img src={CIInsights} alt="CI insights" />
                <h4>CI insights</h4>
                <p>
                  Spot flaky tests and improve the performance of your CI
                  pipeline.
                </p>
              </div>
              <div>
                <img src={ReviewTime} alt="Review time" />
                <h4>Review time</h4>
                <p>
                  Identify review bottlenecks and get your work merged faster.
                </p>
              </div>
              <div>
                <img src={ReviewRate} alt="Review rate" />
                <h4>Review rate</h4>
                <p>
                  Maintain high code quality by maximizing the percentage of
                  code that gets reviewed before it’s merged.
                </p>
              </div>
              <div>
                <img src={WIPLimits} alt="WIP limits" />
                <h4>WIP limits</h4>
                <p>
                  Allow teams to set their own work in progress limits to boost
                  focus and productivity.
                </p>
              </div>
            </FeaturesGrid>
          </Block>
        </FeaturesGridWrapper>

        <Block type="padding-top-small padding-bottom-large">
          <LeadHeading
            title="The GitHub metrics we don’t track"
            description="Not all metrics are created equal. We’ve consciously left out some activity metrics that tend to do more harm than good."
          />
          <ThreeColumns>
            <div className="columns three-columns">
              <div className="column">
                <h4>Developer stack ranking</h4>
                <p>
                  Swarmia focuses on improving development productivity on the
                  team and organization level. That’s why you won’t find Git
                  commit leaderboards in Swarmia.
                </p>
              </div>
              <div className="column">
                <h4>Lines of code (LOC)</h4>
                <p>
                  Lines of code is a terrible way to measure engineering
                  productivity. In fact, Swarmia only records lines of code in
                  the context of batch size and code review notifications.
                </p>
              </div>
              <div className="column">
                <h4>Code churn and rework</h4>
                <p>
                  We believe that rewriting your own code is a perfectly
                  acceptable way to solve complex problems. That’s why you won’t
                  be able to track code churn or rework with Swarmia.
                </p>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <ReadNext
          label="Explore next"
          heading="Working Agreements"
          icon={continuousIcon}
          url="/product/working-agreements/"
        >
          <p className="large_text">
            Tracking the right metrics is just the first step. To make sure your
            insights don&#39;t just sit on a dashboard, Swarmia allows your
            teams to set their own Working Agreements.
          </p>
        </ReadNext>
      </Page>
      <CTASection>
        Swarmia is how the best teams get better. Get started with a demo or
        a 14-day trial.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/git-analytics/',
          '/blog/measuring-software-development-productivity/',
          '/blog/dora-metrics/',
        ]}
        heading="Learn more from our blog"
      />
    </Layout>
  )
}

export default SwarmiaForDevsPage

const FeaturesGridWrapper = styled.div`
  border-top: 1px rgba(0, 0, 0, 0.05) solid;
  text-align: center;
  p {
    margin-top: 0.75rem;
  }
`

const FeaturesGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem 3rem;
  h3 {
    margin-top: 1.2rem;
  }
  h4 {
    margin-top: 1rem;
  }
  img {
    width: 64px;
    height: 64px;
  }
  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 4rem;
  }
  @media screen and (min-width: ${theme.breakpoints['large']}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
